<template>
  <div class="broadcast-chat" :style="{height: `${height}px`}">
    <div class="welcome d-flex align-items-center justify-content-center"
         v-if="isSubscribed">
      Welcome to the chat room!
    </div>
    <div class="welcome d-flex align-items-center justify-content-center"
         v-else>
      Broadcast Chat
    </div>
    <div class="chats" :style="{height: `calc(${height}px - 6em)` }">
      <div class="comment"
           v-for="(comment, index) in comments"
           :key="index"
      >
        <span class="publisher" :class="{me: uuid === comment.publisher}">
          {{ uuid === comment.publisher ?
            'You' : comment.message.publisherName }}
        </span>:
        <span class="message" v-html="comment.message.message"></span>
      </div>
    </div>
    <div class="write-message pt-2 pb-1 pl-2">
      <picker :style="{ position: 'absolute', bottom: '100px', right: '20px' }"
              :set="'emojione'"
              :showPreview="false"
              :showCategories="false"
              :showSkinTones="false"
              title="Emoji"
              @select="selectEmoji"
              v-if="showEmojiCart && isSubscribed"
      />
      <div class="chat-features">
        <span class="far fa-smile" @click="toggleEmojiCart"/>
      </div>
      <div class="d-flex align-items-center">
        <input class="form-control message-input"
               type="text"
               placeholder="Write something..."
               v-model="message"
               :disabled="!isSubscribed"
               @keyup.enter="send"
               @focus="showEmojiCart = false"
        />
        <button class="btn btn-link"
                :disabled="message.trim().length === 0"
                @click="send"
        >
          <span class="fa fa-paper-plane"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Picker } from 'emoji-mart-vue-fast';
  import 'emoji-mart-vue-fast/css/emoji-mart.css';


  export default {
    name  : 'Chat',
    props : [
      'channel',
      'height',
      'uuid',
      'isSubscribed',
      'pnInstance',
    ],
    data() {
      return {
        comments      : [],
        message       : '',
        showEmojiCart : false,
      };
    },
    components : {
      Picker,
    },
    computed : {
      ...mapGetters({
        user : 'user/user',
      }),
    },
    watch : {
      isSubscribed() {
        if (this.isSubscribed)
          this.comments = this.getChannelMessage();
        else
          this.comments = [];
        
      },
    },
    mounted() {
      this.comments = this.getChannelMessage();
    },
    updated() {
      this.$nextTick(() => {
        if (this.message.length === 0)
          this.scrollToEnd();
        
      });
    },
    methods : {
      getChannelMessage() {
        return this.$pnGetMessage(this.channel, null, null, this.pnInstance);
      },

      /**
       * Scroll to end
       */
      scrollToEnd() {
        const container = this.$el.querySelector('.chats');
        container.scrollTop = container.scrollHeight;
      },

      /**
       * Send Message
       */
      send() {
        this.showEmojiCart = false;
        if (this.message.trim().length > 0) {
          this.$pnPublish({
            channel : this.channel,
            message : {
              message       : this.message,
              publisherName : this.user.nickname,
            },
          }, null, this.pnInstance);

          this.message = '';
        }
      },

      /**
       * Toggle Show/Hide Emoji Cart
       */
      toggleEmojiCart() {
        if (this.isSubscribed)
          this.showEmojiCart = !this.showEmojiCart;
        
      },

      /**
       * Select an Emoji
       *
       * @param emoji
       */
      selectEmoji(emoji) {
        const input = this.$el.querySelector('.message-input');

        // input.insertAtCaret(wrapEmoji2(emoji.native));
        input.insertAtCaret(emoji.native);
        this.message = input.value;
      },
    },
  };

  HTMLInputElement.prototype.insertAtCaret = function (text) {
    text = text || '';
    if (document.selection) {
      // IE
      this.focus();
      const sel = document.selection.createRange();
      sel.text = text;
    } else if (this.selectionStart || this.selectionStart === 0) {
      // Others
      const startPos = this.selectionStart;
      const endPos = this.selectionEnd;
      this.value = this.value.substring(0, startPos) +
        text +
        this.value.substring(endPos, this.value.length);
      this.selectionStart = startPos + text.length;
      this.selectionEnd = startPos + text.length;
    } else
      this.value += text;
    
  };


</script>

<style lang="scss">
  @import '../../../assets/scss/components/user/broadcast/chat';
</style>